body {
    margin: 0;
    padding: 0;
  }

.particles {
    position: fixed;
    right: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }