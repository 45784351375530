.header {
    height: 8vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    background-color: black;
    /*position: fixed;
    margin-top: -20px;*/

    .logo-container {
        
        height: 8vh;
        width: 8vh;
        /*padding: 10px 10px 10px 10px ;*/
    }

    .options {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .option {
            padding: 0px 15px;
            cursor: pointer;
            color: white;
            font-size: 1vw;
        }
    }
}