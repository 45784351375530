.homepage {

    .component {
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .text {
        text-align: center;
    }

    .textTitle {
        text-align: center;
        font-family: 'Dancing Script', cursive;
        font-size: 5vw;
    }

    .textSubtitle {
        text-align: center;
        font-family: 'Dancing Script', cursive;
        font-size: 3vw;
        margin-top: -50px;
    }

    .image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50vw;
        height: 100%;
    }
}